<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-4">
    <h2>Relatório de Senhas</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>
        <a>Relatórios</a>
      </li>
      <li class="active">
        <strong>Relatório de Senhas</strong>
      </li>
    </ol>
  </div>
  <div class="col-sm-8">
    <div class="title-action" *ngIf="pager.total > 0">
      <button type="button" [disabled]="exporting" (click)="exportarCSV()" class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar CSV' }}
      </button>
      <button type="button" [disabled]="exporting" (click)="exportarExcel()" class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar XLSX' }}
      </button>
      <button type="button" [disabled]="exporting" (click)="exportarPDF()" class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar PDF' }}
      </button>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content pb-0 animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Filtros </h5>
        </div>
        <div class="ibox-content">
          <form #atendimentoForm="ngForm" class=" " novalidate>
            <div class="row">
              <div class="col-lg-4">
                <mat-form-field style="width:25vw">
                  <mat-label>Selecione o período</mat-label>
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" readonly>
                    <input matStartDate formControlName="start" placeholder="Start date" readonly>
                    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="loadPage(1)" readonly>
                  </mat-date-range-input>
                  <!-- <mat-hint>dd/mm/aaaa - dd/mm/aaaa</mat-hint> -->
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                      <select class="form-control" name="prefixo" id="prefixo" (change)="loadCustomDateRange($event)"
                        style="margin-right: 5px;">
                        <option [value]="0">Customizado</option>
                        <option [value]="7">Últimos 7 dias</option>
                        <option [value]="15">Últimos 15 dias</option>
                        <option [value]="30">Últimos 30 dias</option>
                        <option [value]="90">Últimos 90 dias</option>
                        <option [value]="180">Útlimos 6 meses</option>
                        <option [value]="365">Útlimos 12 meses</option>
                      </select>
                      <button matDateRangePickerCancel #matDateRangePickerCancelSenha
                        style="display: none">Close</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply>OK</button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>

                  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Data de início
                    inválida</mat-error>
                  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Data de fim inválida</mat-error>
                </mat-form-field>
              </div>
              <div [ngClass]="{'col-lg-4': locationEnabled, 'col-lg-8': !locationEnabled}">
                <div class="form-group">
                  <mat-form-field [ngStyle]="{'width': locationEnabled ? '25vw' : '50vw'}">
                    <mat-label>Estabelecimento</mat-label>
                    <mat-select [(ngModel)]="idFranquiaSelecionada" name="idFranquiaSelecionada"
                      id="idFranquiaSelecionada" (selectionChange)="loadPage(1)">
                      <mat-option [value]="''"> Todos</mat-option>
                      <mat-option *ngFor="let f of franquias" [value]="f._id">
                        {{f.nome}} {{!!f.nomeFantasia ? ' | ' + f.nomeFantasia : ''}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-4" *ngIf="locationEnabled">
                <mat-form-field style="width:25vw">
                  <mat-label>Localização</mat-label>
                  <mat-select [(ngModel)]="idLocalizacaoSelecionada" name="idLocalizacaoSelecionada"
                    id="idLocalizacaoSelecionada" (selectionChange)="loadPage(1)">
                    <mat-option [value]="''"> Todos</mat-option>
                    <mat-option *ngFor="let m of localizacoes" [value]="m._id">
                      {{m.cep}} - {{m.nome}} - {{m.bairro}} - {{m.cidade}}/{{m.estado}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="wrapper wrapper-content animated fadeIn">

  <div class="row">
    <div class="col-lg-6">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Quantidade de Senha geradas </h5>
        </div>
        <div class="ibox-content" [ngClass]="{'sk-loading': loadingCharts}">
          <div class="sk-spinner sk-spinner-wave">
            <div class="sk-rect1"></div>
            <div class="sk-rect2"></div>
            <div class="sk-rect3"></div>
            <div class="sk-rect4"></div>
            <div class="sk-rect5"></div>
          </div>
          <canvas id="relatorio-senha"></canvas>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Quantidade de Senhas por Fila </h5>
        </div>
        <div class="ibox-content" [ngClass]="{'sk-loading': loadingCharts}">
          <div class="sk-spinner sk-spinner-wave">
            <div class="sk-rect1"></div>
            <div class="sk-rect2"></div>
            <div class="sk-rect3"></div>
            <div class="sk-rect4"></div>
            <div class="sk-rect5"></div>
          </div>
          <canvas id="relatorio-group-filas"></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="ibox-title">
        <h5>Relatório de Senhas <span *ngIf="pager.total > 0">({{pager.total}})</span> </h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">

          <table class="table table-striped" *ngIf="pager.total > 0" id="table-relatorio-senhas">
            <thead>
              <tr>
                <th class="text-center">Estabelecimento</th>
                <th class="text-center">Data</th>
                <th class="text-center">Fila</th>
                <th class="text-center">Senha</th>
                <th class="text-center">Comparecimento</th>
                <th class="text-center">Nome/Telefone/Email</th>
                <th class="text-center hidden-xs">Campos</th>
                <th *ngIf="locationEnabled" class="text-center">Localização</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of pager.list; let i = index">
                <th class="text-center">{{item.franquia.nome}}</th>
                <td class="text-center">{{item.data | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                <td class="text-center">{{item.fila.nome}}</td>
                <td class="text-center">{{item.senha}}</td>
                <td class="text-center">
                  <span class="label label-danger" style="margin-right: 2px;" *ngIf="item.saiuDaFila">SAIU DA
                    FILA</span>
                  <span class="label label-success" style="margin-right: 2px;"
                    *ngIf="!item.saiuDaFila && item.app === 'PAGER' && item.compareceu">COMPARECEU</span>
                  <span class="label label-warning" style="margin-right: 2px;"
                    *ngIf="!item.saiuDaFila && item.app === 'PAGER' && !item.compareceu">NÃO COMPARECEU</span>
                </td>
                <td class="text-center">{{item.nome}} {{item.numero ? ' | ' + item.numero : ''}} {{item.email ? ' | ' +
                  item.email : ''}}</td>
                <td class="text-center hidden-xs">
                  <span *ngFor="let campo of item?.campos">
                    <b>{{campo.key}}:</b> {{campo.value}}<br />
                  </span>
                </td>
                <td class="text-center hidden-xs" *ngIf="locationEnabled">
                  {{item.localizacao?.nome}}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-center" *ngIf="pager.total > pager.perPage">
            <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
          </div>

        </div>
      </div>
    </div>
    <br />
    <br />

  </div>
</div>