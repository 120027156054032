<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-4">
    <h2>Relatórios</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>
        <a>Relatórios</a>
      </li>
      <li class="active">
        <strong>Avaliações </strong>
      </li>
    </ol>
  </div>
  <div class="col-sm-8">
    <div class="title-action">
      <button type="button" [disabled]="exporting" (click)="exportarCSV()" *ngIf="pager.total > 0"
        class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar CSV' }}
      </button>
      <button type="button" [disabled]="exporting" (click)="exportarExcel()" *ngIf="pager.total > 0"
        class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar XLSX' }}
      </button>
      <button type="button" [disabled]="exporting" (click)="exportarPDF()" *ngIf="pager.total > 0"
        class="btn btn-primary">
        <img *ngIf="exporting"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{ exporting ? ' Exportando...' : 'Exportar PDF' }}
      </button>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content pb-0 animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Filtros </h5>
        </div>
        <div class="ibox-content">
          <form #atendimentoForm="ngForm" class=" " novalidate>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <mat-form-field style="width:25vw">
                    <mat-label>Selecione o período</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" readonly>
                      <input matStartDate formControlName="start" placeholder="Start date" readonly>
                      <input matEndDate formControlName="end" placeholder="End date" (dateChange)="loadPage(1)"
                        readonly>
                    </mat-date-range-input>
                    <!-- <mat-hint>dd/mm/aaaa - dd/mm/aaaa</mat-hint> -->
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker>
                      <mat-date-range-picker-actions>
                        <select class="form-control" name="prefixo" id="prefixo" (change)="loadCustomDateRange($event)"
                          style="margin-right: 5px;">
                          <option [value]="0">Customizado</option>
                          <option [value]="7">Últimos 7 dias</option>
                          <option [value]="15">Últimos 15 dias</option>
                          <option [value]="30">Últimos 30 dias</option>
                          <option [value]="90">Últimos 90 dias</option>
                          <option [value]="180">Útlimos 6 meses</option>
                          <option [value]="365">Útlimos 12 meses</option>
                        </select>
                        <button matDateRangePickerCancel #matDateRangePickerCancelAvaliacao
                          style="display: none">Close</button>
                        <button mat-raised-button color="primary" matDateRangePickerApply>OK</button>
                      </mat-date-range-picker-actions>
                    </mat-date-range-picker>

                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Data de início
                      inválida</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Data de fim inválida</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="form-group">
                  <mat-form-field style="width:50vw">
                    <mat-label>Estabelecimento</mat-label>
                    <mat-select [(ngModel)]="idFranquiaSelecionada" name="idFranquiaSelecionada"
                      id="idFranquiaSelecionada" (selectionChange)="loadPage(1)">
                      <mat-option [value]="''"> Todos</mat-option>
                      <mat-option *ngFor="let f of franquias" [value]="f._id">
                        {{f.nome}} {{!!f.nomeFantasia ? ' | ' + f.nomeFantasia : ''}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content animated fadeIn">

  <div class="row">

    <div class="col-lg-6">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Quantidade de Avalições no período </h5>
        </div>
        <div class="ibox-content" [ngClass]="{'sk-loading': loadingCharts}">
          <div class="sk-spinner sk-spinner-wave">
            <div class="sk-rect1"></div>
            <div class="sk-rect2"></div>
            <div class="sk-rect3"></div>
            <div class="sk-rect4"></div>
            <div class="sk-rect5"></div>
          </div>
          <canvas id="relatorio-avaliacao"></canvas>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="ibox ">
        <div class="ibox-title">
          <h5>Quantidade de Avaliações por Estabelecimento </h5>
        </div>
        <div class="ibox-content" [ngClass]="{'sk-loading': loadingCharts}">
          <div class="sk-spinner sk-spinner-wave">
            <div class="sk-rect1"></div>
            <div class="sk-rect2"></div>
            <div class="sk-rect3"></div>
            <div class="sk-rect4"></div>
            <div class="sk-rect5"></div>
          </div>
          <canvas id="relatorio-avaliacao-pie"></canvas>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Relatório de Avaliações ({{pager.total}})</h5>
        </div>

        <div class="ibox-content">
          <div class="table-responsive">

            <table class="table table-striped" *ngIf="pager.total > 0" id="table-relatorio-senhas">
              <thead>
                <tr>
                  <th class="text-center" style="width: 12%;">Avaliação</th>
                  <th class="text-center" style="width: 20%;">Estabelecimento/Data</th>
                  <th class="text-center" style="width: 20%;">Dados do Cliente</th>
                  <th class="text-center">Comentário</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pager.list; let i = index">
                  <th class="text-center">
                    <avaliacao-embeded [pontuacao]="item.rating"></avaliacao-embeded>
                  </th>
                  <th class="text-center">
                    {{item.franquia.nome}}<br />
                    {{item.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}
                  </th>
                  <td class="text-center">
                    {{item?.nome}}<br />
                    {{item?.telefone}}<br />
                    {{item?.email}}
                  </td>
                  <td class="text-center hidden-xs">{{item?.comentario}}</td>
                </tr>
              </tbody>
            </table>

            <div class="text-center" *ngIf="pager.total > pager.perPage">
              <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
            </div>

            <div class="panel text-center" *ngIf="pager.total === 0">
              <div class="panel-body">
                <p class="p-y-1">
                  <i class="fa fa-table fa-2x font-size-46 line-height-1 text-primary"></i>
                </p>
                <p>
                  <strong>Relatório de Avaliações</strong>
                </p>
                <p class="m-b-4">Nenhum registro encontrado.</p>
              </div>
            </div>


            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="hr-line-dashed"></div>